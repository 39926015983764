import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { trackingGA } from '@merchx-v2/helpers'
import { navigate } from 'utils/navigate'
import { Button, Footer, Header, Notification, SEO } from "components";

import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import { selectors } from "state/LoginPage/reducer";
import * as styles from "./ProfileConfirmDeactivationPage.module.scss";

const ProfileConfirmDeactivationPage = ({
  loginState,
  menu,
  resetMenuFilters,
  setMenuCollection,
}) => {
  const [notificationTitle, setNotificationTitle] = useState("Error");
  const [notification, showNotification] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    trackingGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  if (typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined') {
    return null
  }

  const refreshToken =
    localStorage.getItem("refreshToken") ||
    sessionStorage.getItem("refreshToken");

  if (!refreshToken) {
    return null;
  }

  return (
    <div className={styles.container}>
      <SEO pathname='/profile/confirm-deactivation' />
      <Header
        menu={menu}
        onCollectionClicked={handleCollectionClicked}
        onResetCollection={handleReset}
      />
      <div className={styles.content}>
        <div className={styles.box}>
          <div className={styles.title}>Your VIP membership is deactivated</div>
          <p className={styles.text}>
            You've cancelled your VIP membership with us. You will no longer
            have access to VIP prices. If you want to come back, FMF VIP is just
            a click away.
          </p>
          <p className={styles.text}>We hope to welcome you back soon!</p>
          <div className={styles.button}>
            <Button
              value="Back to my accounts"
              className="buttonSmall"
              customAction={() => navigate("/profile")}
              loading={loginState.UIState.isLoading}
            />
          </div>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
        <Notification
          title={notificationTitle}
          message={notification}
          onNotificationClosed={() => {
            setNotificationTitle("Error");
            showNotification("");
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: selectMenu(state),
  loginState: selectors.selectLoginState(state),
});

const mapDispatchToProps = {
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileConfirmDeactivationPage);
